import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const ThankYouPage = () => {
  const [conversionTracked, setConversionTracked] = useState(false);
  const location = useLocation();
  const { orderId, currency, cartTotal } = location.state;

  useEffect(() => {
    if (!conversionTracked && cartTotal > 0) {
      const cartTotalValue = parseFloat(Number(cartTotal).toFixed(2));
      const orderIdString = orderId.toString();
      const currencyString =
        currency && currency.length === 3 ? currency : "USD";

      if (typeof window !== "undefined" && window.fbq) {
        window.fbq("track", "Purchase", {
          value: cartTotalValue,
          currency: currencyString,
          content_ids: [orderIdString],
          content_type: "product",
          debug: true,
        });
      }

      if (typeof window !== "undefined" && window.etag) {
        window.etag("event", "conversion", {
          value: cartTotal.toString(),
          order_id: orderIdString,
          currency: currencyString,
          transaction_id: "TRANSACTION_ID",
          customer_email: "CUSTOMER_EMAIL",
          customer_id: "CUSTOMER_ID",
          customer_first_name: "CUSTOMER_FIRST_NAME",
          customer_last_name: "CUSTOMER_LAST_NAME",
          customer_total_spent: cartTotal.toString(),
          customer_phone: "CUSTOMER_PHONE",
          customer_accepts_marketing: "CUSTOMER_ACCEPTS_MARKETING",
        });
      }
      setConversionTracked(true);
    }
  }, [conversionTracked, orderId, currency, cartTotal]);

  return (
    <div className="container mt-5 mb-5">
      <div className="row justify-content-center mt-5">
        <div className="col-md-6">
          <div className="card mt-5">
            <div className="card-body">
              <h2 className="card-title text-center mb-4">
                Success Notification
              </h2>
              <p className="text-success text-center">
                Thank you for Ordering!
              </p>
              <p className="text-success text-center">Success your Payment</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYouPage;
